import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Layout from '../../components/layout'
import Header from '../../components/header'
import Container from '../../components/container'
import StoryIntro from '../../components/story-intro'
import Row from '../../components/row'
import Column from '../../components/column'

const PageHeader = <Header title="Test finger" />

const PrueffingerPage = ({ location }) => {
    const images = useStaticQuery(graphql`
      {
        allFile(filter: {relativeDirectory: {eq: "stories/prueffinger"}}, sort: {fields: name}) {
          nodes {
            childImageSharp {
              gatsbyImageData(
                width: 1050
                height: 650
                transformOptions: {
                  cropFocus: CENTER
                }
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
            name
          }
        }
      }
    `)

    return (
        <Layout translatedPageLinks={[{ code: 'de', link: '/de/story/prueffinger/' }, { code: 'en', link: '/en/story/test-finger/' }]} pageLang="en" pageTitle="Test finger" pageHeader={PageHeader} location={location}>
            <Container>
                <section>
                    <StoryIntro>
                        <p>
                            Touch controls have conquered the world. 
                            It is no longer possible to imagine everyday life without them. 
                            They are also being used increasingly in the automotive environment, and even exclusively in some models.
                        </p>
                    </StoryIntro>
                    <p>
                        In order to be able to test capacitive user interfaces under laboratory conditions and to unify touch surfaces, GE-T has developed a test finger. 
                        Instead of the previously used metal cylinders, GE-T created a new type of tool to reproduce the human finger. 
                        By doing this, GE-T created a new standard that is used within the Volkswagen Group and by numerous suppliers. 
                        Several prominent companies outside the automotive industry are also already using the GE-T test finger.
                    </p>
                    <Row>
                        <Column columnWidth={2}>
                            <GatsbyImage image={getImage(images.allFile.nodes[0])} alt="" />
                        </Column>
                        <Column columnWidth={2}>
                            <GatsbyImage image={getImage(images.allFile.nodes[2])} alt="" />
                        </Column>
                    </Row>
                    <p>
                        The GE-T test finger corresponds to the human finger in several of its haptic and electrical properties: 
                        it is highly reliable even after more than 1,000,000 actuations and functions at temperatures from minus 40 to plus 90 degrees Celsius. 
                        The wide range of operating humidity lies between zero and 98 percent relative humidity – even in salt water.
                    </p>
                    <p>
                        However, we are not stopping there. GE-T continues to research innovative solutions for the experimental testing of touch surfaces.
                    </p>
                    <GatsbyImage image={getImage(images.allFile.nodes[1])} alt="" />
                </section>
            </Container>
        </Layout>
    )
}

export default PrueffingerPage